<template>
  <transition name="van-slide-right">
    <router-view/>
  </transition>
</template>
<style>
html,body{
  height: 100%;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.center{
  text-align: center;
}
.right{
  text-align: right;
}
</style>
