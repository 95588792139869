import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import 'amfe-flexible'
import 'vant/lib/index.css';
import animated from 'animate.css' 
import { vueBaberrage } from './components/vuebaberrage/index.js'

createApp(App)
.use(router)
.use(vant)
.use(vueBaberrage)
.use(animated)
.mount('#app')
