import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {path: '/',name: 'login',component: () => import('../views/login.vue')},
  {path: '/luckDraw',name: 'luckDraw',component: () => import('../views/luckDraw.vue')},
  {path: '/runLuckDraw',name: 'runLuckDraw',component: () => import('../views/runLuckDraw.vue')},
  {path: '/luckDrawUserList',name: 'luckDrawUserList',component: () => import('../views/luckDrawUserList.vue')},
  {path: '/ranking',name: 'ranking',component: () => import('../views/ranking.vue')},
  {path: '/blessing',name: 'blessing',component: () => import('../views/blessing.vue')},

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
